.grid{
   cursor: pointer;
   display: flex;
   justify-content: center;
   justify-items: center;
   
   margin-bottom: 50px;
   padding-left: 200px;
   padding-right: 200px;

}
@media (max-width: 1220px) {
   .grid {
      padding-left: 20px;
      padding-right: 20px;
   }
}