.imageresponsive{
    margin-left: 200px;
    width: 500px;
    /* justify-items: center;
    justify-content: center;
    display: flex; */
}

@media only screen and (max-width: 1200px) {
   
    .imageresponsive {
        margin-left: 10px;
        margin-right: 10px;
        width: 300px;
    }
  }


  .textresponsive{
    margin-right: 200px;
}

@media only screen and (max-width: 1280px) {
   
    .textresponsive {
        margin-left: 10px;
        margin-right: 10px;
    }
  }