
 

.header {
  display: flex;
  background-color: white;
  /* background: linear-gradient(#ffffff, #0275d9); */
  justify-content:  space-around;
  align-items: center;
  padding: 0px 40px;
  height: 80px;
  
}
.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
 
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 100px;
}
.logo {
  width: 280px;
  /* height: 250px; */
}
.nav-options {
  padding-left: 25px;
  display: flex;
  grid-template-columns: repeat(3, auto);
  grid-gap: 50px;
  list-style-type: none;
  margin-left: 100px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: large;
  

}

.mobile-option {
  display: none;
}

.option :hover {
  color: rgb(45, 5, 245);
}


.mobile-menu {
  display: none;
}
@media (max-width: 900px) {
  .header {
    padding: 0px 10px;
    width: 100%;
  }
  .logo {
    width: 200px;
    height: 200px;
  }
  .nav-options {
    display: flex;
    width: 100%;
    height: 250px;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background: #8b8b8d;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 2;
    align-content: center;
    padding-left: 0px;
    justify-content: center;
    margin-left: 0px;
    
  }

  .menu-icon {
    width: 45px;
    height: 45px;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 10vw;
    padding: 30px 0px;
  }
  
  .mobile-menu {
    display: block;
  }
}
