.main {
    margin: 7rem auto;
    width: 90%;
    max-width: 40rem;
  }


  @import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
.App {
  font-family: Montserrat;
  font-weight: 600;
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


